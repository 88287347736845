import BaseBean from "@/utils/BaseBean";

export interface IClientRepairManualDataObj {
    utilInst:ClientRepairManualUtil
    refMap:Map<string,any>
    pageListRef:any
    listData:Array<any>
    listDataBak:Array<any>
    otherParams:any
}

export default class ClientRepairManualUtil extends BaseBean{
    public dataObj:IClientRepairManualDataObj

    constructor(proxy:any,dataObj:IClientRepairManualDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //加载手册的车型
    public async clientQueryManualCx():Promise<any>{
        this.dataObj.otherParams.toolBrandData=await this.utils.ToolsProviderApi.clientQueryManualCx({flag:0});
    }
    //加载金杯、斯威、系能源的手册(按品牌加载所有车型的手册-使用、操作手册)
    public async queryManualByType():Promise<any>{
        const loading = this.proxy.$loading({lock: true,text: '正在加载，请稍后......',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
        let res=await this.utils.ToolsProviderApi.queryManualByType({type:1});
        if(res.code && res.code=='0000'){
            this.dataObj.listData=JSON.parse(res.data);
            Object.assign(this.dataObj.listDataBak,this.dataObj.listData);
        }
        loading.close();
    }
    //预览手册
    public async viewManual(newValue:string):Promise<void>{
        const loading = this.proxy.$loading({lock: true,text: '正在执行，请稍后......',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
        let res=await this.utils.ToolsProviderApi.clientQueryManualInfo({id:newValue});
        loading.close();
        if(res.result && res.url)await this.proxy.$router.replace({path:'/clientViewFile',query:{fileUrl:res.url,fileName:res.preName,moduleType:2}});
    }
    //过滤手册（不在去查询数据库了，直接从备份结果中过滤）
    public filerManual():void{
        if(this.dataObj.otherParams.queryParam.filterKey || this.dataObj.otherParams.queryParam.brandId){
            let searchResult=[];
            for(let i=0;i<this.dataObj.listDataBak.length;i++){
                let cxName=this.dataObj.listDataBak[i]['cx'];
                let brandId=this.dataObj.listDataBak[i]['brandId'];
                if(this.dataObj.otherParams.queryParam.filterKey && this.dataObj.otherParams.queryParam.brandId){
                    if(cxName.indexOf(this.dataObj.otherParams.queryParam.filterKey)!=-1 && this.dataObj.otherParams.queryParam.brandId==brandId)searchResult.push(this.dataObj.listDataBak[i]);
                } else if(this.dataObj.otherParams.queryParam.filterKey){
                    if(cxName.indexOf(this.dataObj.otherParams.queryParam.filterKey)!=-1)searchResult.push(this.dataObj.listDataBak[i]);
                }else if(this.dataObj.otherParams.queryParam.brandId){
                    if(this.dataObj.otherParams.queryParam.brandId==brandId)searchResult.push(this.dataObj.listDataBak[i]);
                }
            }
            this.dataObj.listData=searchResult;
        }else{//如果是清空了查询条件，则展示所有的
            this.dataObj.listData=this.dataObj.listDataBak;
        }
    }
}